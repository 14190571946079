class LoanNameFormatter{
    format(name:string){
        
        switch(name){
           case "seniorDebtLoan" : return "Senior Debt Loan";
           case "equipmentLoan" : return "Equipment Loan";
           case "mazzanineLoan" : return "Mazzanine Loan";
           case "microLoan" : return "Micro Loan";
           case "personalLoan" : return "Personal Loan";
           case "realEstateLoan" : return "Real Estate Loan";
           case "sbaLoan" : return "SBA Loan";
           default : return "";
        }
    }
}


export default new LoanNameFormatter();