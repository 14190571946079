const Nrupee = ({color,className}:{color:string,className?:string})=>{

    return(
        <>

            <svg className={className || ""} height="800px" width="800px" version="1.1"
                viewBox="0 0 512 512"  xmlSpace="preserve">
            <g>
                <path {...(!className ? {fill:color} : {} ) } d="M318.213,66.588h107.818L465.37,0H85.969L46.63,66.588h145.727c32.137,9.476,58.259,28.504,72.702,52.656
                    H85.969l-39.34,66.588h227.316c-13.482,45.473-65.618,79.365-127.924,79.365H68.313v60.013L288.818,512h96.012v-23.222
                    L183.333,321.936c84.557-3.351,153.634-61.218,166.283-136.105h76.415l39.339-66.588H345.687
                    C340.062,100.028,330.637,82.256,318.213,66.588z"/>
            </g>
            </svg>
        </>
    )
}

export default Nrupee;