const Neuro = ({color,className}:{color:string,className?:string})=>{

    return(
        <>
        <svg className={className || ""} version="1.1"
            width="800px" height="800px" viewBox="0 0 512 512"  xmlSpace="preserve">
        <g>
            <path {...(!className ? {fill:color} : {} ) } d="M433.156,104.281l-12.969-5.469c-21.297-9-43.453-13.5-65.844-13.5c-64.766,0-124.234,37.531-152.813,95.047
                h216l-13.328,64.484H184c-0.266,3.656-0.422,7.406-0.422,11.156c0,9.219,0.813,18.594,2.375,28.031h210.266l-13.25,64.5H211.063
                c31.344,48.734,84.719,78.25,143.281,78.25c19.859,0,39.781-3.625,59.188-10.875l15.469-5.75v90.734l-8.484,2.266
                c-21.75,5.875-44.031,8.844-66.172,8.844c-105.422,0-200.266-65.344-238.375-163.469H61.422v-64.5h38.688
                c-1.188-9.875-1.766-19.094-1.766-28.031c0-3.688,0.094-7.5,0.25-11.156H61.422v-64.484h48.672C143.141,73.75,242.313,0,354.344,0
                c29.75,0,58.969,5.234,86.938,15.438l9.297,3.438L433.156,104.281z"/>
        </g>
        </svg>
        </>
    )
}

export default Neuro;