const Npound = ({color,className}:{color:string,className?:string})=>{

    return(
        <>

        <svg className={className || ""} height="800px" width="800px" version="1.1" 
            viewBox="0 0 512 512"  xmlSpace="preserve">
        <g>
            <path {...(!className ? {fill:color} : {} ) } d="M213.035,422.764c17.332-34.522,22.857-70.265,22.9-100.568c-0.021-5.806-0.238-11.406-0.604-16.77h102.348
                V244.83H229.503c0-25.695,0-62.948,0-92.668c0.021-21.821,8.719-41.246,23.007-55.577c14.353-14.288,33.757-23.008,55.577-23.008
                c30.174-0.043,56.246,16.9,69.52,41.828l64.988-34.49C417.105,32.817,366.363,0.021,308.088,0
                c-84.046,0.021-152.119,68.116-152.141,152.162c0,29.72,0,66.972,0,92.668H81.851v60.595h79.557
                c0.582,5.158,1.014,10.727,0.972,16.77c0.043,21.152-4.209,46.189-15.022,67.438c-10.987,21.378-27.152,39.378-55.663,51.81
                c-16.037,6.864-25.038,24.13-21.54,41.192C73.671,499.708,88.757,512,106.175,512c165.868,0,278.339,0,278.339,0v-73.512
                c0,0-69.865-0.022-180.437-0.022C207.337,433.285,210.402,428.062,213.035,422.764z"/>
        </g>
        </svg>
        </>
    )
}

export default Npound;