import React from 'react'
import { resultType } from '../../@types/user'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import NumberFormat from '../../utils/NumberFormat';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


type loanType = {
    result:resultType,
    myCurrency:string,
    selectedLoan:string
}
export default function SingleLoanScreen({result,myCurrency,selectedLoan}:loanType) {

    const activeTabJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs); 


  return (
    <div>
        {
            result.map((el,ind)=>(
                <div key={`tabAmm${ind}`} style={{margin:"50px 20px"}}>
                    <p>Scenario Name : {el.name}</p>
                    {
                        (el.output?.loans[selectedLoan] || []).map((elem:any,index:number)=>(
                            <div key={`ltt${index}`}>
                                <p>Job Name : {activeTabJobs[index].name}</p>
                                {
                                    elem.map((l:any,i:number)=>(
                                        <div key={`lttn${i}`} style={{margin:"25px 0"}}>
                                            <div style={{background:"white",padding:"10px"}}>
                                                <p>Loan Name : {l.name}</p>
                                                <p>Overall Interest : {NumberFormat.convertAccordingCurrency(myCurrency,l.totalData.interest)}</p>
                                                <p>Overall Principal Redux: {NumberFormat.convertAccordingCurrency(myCurrency,l.totalData.principalRedux)}</p>
                                            </div>
                                            <div
                                            style={{
                                                display:"flex",
                                                overflowX:"scroll",
                                                gap:5
                                            }}
                                            >
                                            {
                                                l.data.map((mel:any,mind:number)=>(
                                                    <div 
                                                    key={`mo${mind}`} 
                                                    >
                                                        <TableContainer sx={{padding:2,width:700}} component={Paper}>
                                                            <p>({mel.fiscalStart}) - ({mel.fiscalEnd})</p>
                                                            <Table aria-label="simple table">
                                                                <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Loan No.</TableCell>
                                                                    <TableCell align="right">PMT</TableCell>
                                                                    <TableCell align="right">Principal</TableCell>
                                                                    <TableCell align="right">Interest</TableCell>
                                                                    <TableCell align="right">PrincipalRedux</TableCell>
                                                                    <TableCell align="right">PrincipalBalance</TableCell>
                                                                </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                        
                                                                {
                                                                    mel.data.map((r:any,ri:number)=>(
                                                                        <TableRow 
                                                                        key={`lttnt${ri}`}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                                        >
                                                                            <TableCell component="th" scope="row">{ri === l.length -1 ? "Total" : r.index}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.PMT)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.Principal)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.Interest)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.PrincipalRedux)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.PrincipalBalance)}</TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }

                                                                    <TableRow 
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell component="th" scope="row">Year Total</TableCell>
                                                                            <TableCell align="right">-</TableCell>
                                                                            <TableCell align="right">-</TableCell>
                                                                            <TableCell align="right">{mel.totalData.interest}</TableCell>
                                                                            <TableCell align="right">{mel.totalData.principalRedux}</TableCell>
                                                                            <TableCell align="right">-</TableCell>
                                                                        </TableRow>

                                                                    
                                                            
                                                                </TableBody>
                                                            </Table>
                                                    
                                                        </TableContainer>
                                                    </div>
                                                ))
                                            }
                                                
                                            </div>

                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                
                </div>
            ))
        }
      
    </div>
  )
}
