import Ndollar from "../svg/currency/naked/Ndollar";
import Neuro from "../svg/currency/naked/Neuro";
import Npound from "../svg/currency/naked/Npound";
import Nrupee from "../svg/currency/naked/Nrupee";
import Nyen from "../svg/currency/naked/Nyen";

const CurrencyIconRenderer = ({type,color,className}:{type:string,className?:string,color?:string})=>{

    return(
        <div className="CCurrencyHolder">
        {
            type === "USD" &&
            <Ndollar className={className} color={color || "#656a72"} />
        }
         {
            type === "INR" &&
            <Nrupee className={className} color={color || "#656a72"} />
        }
         {
            type === "GBP" &&
            <Npound className={className} color={color || "#656a72"} />
        }
         {
            type === "EUR" &&
            <Neuro className={className} color={color || "#656a72"} />
        }
         {
            type === "JPY" &&
            <Nyen className={className} color={color || "#656a72"} />
        }
         {
            type === "CNY" &&
            <Nyen className={className} color={color || "#656a72"} />
        }
        
        
        </div>
    )

}

export default CurrencyIconRenderer;