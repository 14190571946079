const Nyen = ({color,className}:{color:string,className?:string})=>{

    return(
        <>
            <svg className={className || ""} height="800px" width="800px" version="1.1"
                viewBox="0 0 512 512"  xmlSpace="preserve">
            <g>
                <polygon {...(!className ? {fill:color} : {} ) } points="463.547,0 351.952,0 255.98,175.338 160.048,0 48.453,0 161.032,205.723 64.999,205.723 
                    64.999,283.674 196.974,283.674 196.974,334.478 64.999,334.478 64.999,412.409 196.974,412.409 196.974,512 208.004,512 
                    303.997,512 315.006,512 315.006,412.409 446.981,412.409 446.981,334.478 315.006,334.478 315.006,283.674 446.981,283.674 
                    446.981,205.723 350.968,205.723"/>
            </g>
            </svg>
        </>
    )
}

export default Nyen;