import React, { useEffect, useState } from 'react';
import { resultType } from '../../@types/user';

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import NumberFormat from '../../utils/NumberFormat';


dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);



type loanType = {
    result:resultType,
    setSelectedLoanResult:any,
    myCurrency:string,
    selectedLoan:string,
    setSelectedLoan:(val:string | ((prev:string)=> string) )=> void
}
export default function LoanScreen({result,myCurrency,selectedLoan,setSelectedLoan,setSelectedLoanResult}: loanType) {

    const [mainLoan,setMainLoan] = useState<{
        sceName:string,
        years:{
            yearName:string,
            interests:any,
            total:number,
            yearConstant : number

        }[]
    }[]>([]);


    // Preparing Mappable Array from the Data structure sent by backend
    useEffect(()=>{

        let myMainLoan:{
            sceName:string,
            years:{
                yearName:string,
                interests:any
                total:number,
                yearConstant : number
    
            }[]
        }[] = [];

        result.map((oelem,oind)=>{

            let keys = Object.keys(oelem.output.loans);
            let loanPrep:any[] = [];
            keys.forEach(element => {
                loanPrep.push(oelem.output.loans[element]);
            });
            
            let lowestLoanYear = parseInt(dayjs().format("YYYY")) + 10000;
            let highestLoanYear = 0;
            let ob:any = {};

            loanPrep.forEach((element:any,index:number)=>{

                element.forEach((elem:any,ind:number) => {
    
                    elem.forEach((ielem:any,iind:number) => {
    
                        ielem.data.forEach((nelem:any,nind:number) => {
    
                            // Finding Highest Loan Year
                            if(nelem.loanYearConstant > highestLoanYear){
                                highestLoanYear = nelem.loanYearConstant;
                            }
    
                            // Finding Lowest Loan Year
                            if(nelem.loanYearConstant < lowestLoanYear){
                                lowestLoanYear = nelem.loanYearConstant;
                            }

                            // Separating loans according to their Loan Year with loan year as the key of the object.
                            if(ob[nelem.loanYearConstant]){

                                if(ob[nelem.loanYearConstant][keys[index]]){
                                    (ob[nelem.loanYearConstant][keys[index]].interest += nelem.totalData.interest);
                                }
                                else{
                                    ob[nelem.loanYearConstant]={
                                        ...ob[nelem.loanYearConstant],
                                        [keys[index]] : {
                                            fiscalStart : nelem.fiscalStart,
                                            fiscalEnd : nelem.fiscalEnd,
                                            interest : nelem.totalData.interest,
                                            loanYearConstant : nelem.loanYearConstant
                                        }
                                    };
                                }
                            }
                            else{
                                ob[nelem.loanYearConstant]={
                                    [keys[index]] : {
                                        fiscalStart : nelem.fiscalStart,
                                        fiscalEnd : nelem.fiscalEnd,
                                        interest : nelem.totalData.interest,
                                        loanYearConstant : nelem.loanYearConstant
                                    }
                                };
                            }
    
                        });
                    });
                    
                });
            });

          
            let newKeys = Object.keys(ob); // getting all loan year keys in an array;
          

            // Transforming Data to the type of mainLoan state variable for each Scenario
            let newOb = newKeys.map((elem,ind)=>{
                let innerKey = Object.keys(ob[elem]);
                let tot = 0;
                let inLoans:any = {};
                
                innerKey.forEach((element,index) => {
                    tot += ob[elem][element].interest;
                    inLoans[element] = ob[elem][element].interest;
                    
                });

                return  {
                    yearName : `(${ob[elem][innerKey[0]].fiscalStart}) - (${ob[elem][innerKey[0]].fiscalEnd})`,
                    interests: inLoans,
                    total : parseFloat(tot.toFixed(2)),
                    yearConstant : ob[elem][innerKey[0]].loanYearConstant
                }
            });

            // Pushing Data for Each Scenario
            myMainLoan.push({
                sceName : oelem.name,
                years : newOb
            });

        });


        setMainLoan(myMainLoan); // Setting our mainloan state variable
      
    },[]);


    useEffect(()=>{
        console.log("OOb", mainLoan);
    },[mainLoan]);

    const loanclickHandler = (sceName:string,loanName:string)=>{

        const selec = result.filter((elem)=> elem.name === sceName );

        setSelectedLoanResult(selec);
        setSelectedLoan(loanName);
    }

  return (
    <div>

        {
            mainLoan.map((element,index)=>(
                <div key={`tabAmm${index}`} style={{margin:"50px 20px"}}>
                    <p>Scenario Name : {element.sceName}</p>
                    <div
                    style={{
                        display:"flex",
                        overflowX:"scroll",
                        gap:5
                    }}>

                    {
                        element.years.map((elem,ind)=>(
                            <div
                            key={`mo${ind}`}
                            >

                                <TableContainer sx={{padding:2,width:300}} component={Paper}>
                                    <p>{elem.yearName}</p>
                                    <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Loan Type</TableCell>
                                        <TableCell align="right">Total Interest</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                   
                                   
                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"seniorDebtLoan") }
                                        >
                                            <TableCell align="left">Senior Debt Loan</TableCell>
                                            <TableCell align="right">{elem.interests["seniorDebtLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["seniorDebtLoan"]) : 0}</TableCell>
                                        </TableRow>

                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"equipmentLoan") }
                                        >
                                            <TableCell align="left">Equipment Loan</TableCell>
                                            <TableCell align="right">{elem.interests["equipmentLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["equipmentLoan"]) : 0}</TableCell>
                                        </TableRow>

                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"mazzanineLoan") }
                                        >
                                            <TableCell align="left">Mezzanine Lending</TableCell>
                                            <TableCell align="right">{elem.interests["mazzanineLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["mazzanineLoan"] ) : 0}</TableCell>
                                        </TableRow>

                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"microLoan") }
                                        >
                                            <TableCell align="left">Micro Loan</TableCell>
                                            <TableCell align="right">{elem.interests["microLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["microLoan"]) : 0}</TableCell>
                                        </TableRow>

                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"personalLoan") }
                                        >
                                            <TableCell align="left">Personal Loan</TableCell>
                                            <TableCell align="right">{elem.interests["personalLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["personalLoan"] ) : 0}</TableCell>
                                        </TableRow>

                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"realEstateLoan") }
                                        >
                                            <TableCell align="left">Real Estate Loan</TableCell>
                                            <TableCell align="right">{elem.interests["realEstateLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["realEstateLoan"] ) : 0}</TableCell>
                                        </TableRow>

                                        <TableRow 
                                        className='hoveredRow'
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        onClick={()=> loanclickHandler(element.sceName,"sbaLoan") }
                                        >
                                            <TableCell align="left">SBA Loan</TableCell>
                                            <TableCell align="right">{elem.interests["sbaLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["sbaLoan"]) : 0}</TableCell>
                                        </TableRow>
                                        
                                        <TableRow 
                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                        >
                                            <TableCell align="left">Year Total</TableCell>
                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,elem.total)}</TableCell>
                                        </TableRow>
                                    </TableBody>

                                    </Table>
                                    
                                </TableContainer>
                                
                            </div>
                        ))
                    }

                    </div>

                </div>
            ))
        }
      
    </div>
  )
}
